import React from 'react'
import Layout from '../components/layout'

const PrivacyPage = ({ location }) => (
  <Layout isRoot={false} isWebsite={true} noIndex={true} location={location}>
    <div className="content">
      <h1>プライバシーポリシー</h1>
      <h2>個人情報の利用目的</h2>
      <p>当ウェブサイトでは、お問い合わせの際、名前やメールアドレス等の個人情報を入力いただく場合がございます。</p>
      <p>取得した個人情報は、お問い合わせに対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。</p>

      <h2>広告について</h2>
      <p>当ウェブサイトでは、Google及びGoogleのパートナーウェブサイト（第三者配信事業者）の提供する広告利用しております。</p>
      <p>広告配信にはCookieを使用し、当ウェブサイトを含めた過去のアクセス情報に基づいて広告を配信します。</p>
      <p>Google及びGoogleのパートナーはCookieを使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、適切な広告を表示できます。</p>
      <p>ユーザーは、<a href="https://adssettings.google.com/authenticated" target="_blank" rel="nofollow noopener noreferrer">Google広告設定</a>からパーソナライズ広告を無効にできます。</p>
      <p>または、<a href="https://www.aboutads.info" target="_blank" rel="nofollow noopener noreferrer">www.aboutads.info</a>にアクセスし、パーソナライズ広告に使われる第三者配信事業者のCookieを無効にできます。</p>
      <p>その他、Googleの広告におけるCookieの取り扱いに関する詳細は、<a href="https://policies.google.com/technologies/ads?hl=ja" target="_blank" rel="nofollow noopener noreferrer">Google ポリシーと規約</a>をご確認ください。</p>

      <h2>免責事項</h2>
      <p>当ウェブサイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。</p>
      <p>当ウェブサイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>

      <h2>著作権について</h2>
      <p>当ウェブサイトで掲載している文章や画像などにつきましては、無断での使用及び転載を禁止します。</p>
      <p>当ウェブサイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせよりご連絡ください。迅速に対応いたします。</p>
    </div>
  </Layout>
)

export default PrivacyPage
